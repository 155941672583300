import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state
export interface UiState {
    primaryColor: string;
    secondaryColor: string;
    themeMode: "light" | "dark";
    mobileDrawerOpen: boolean;
}

// Define the initial state using that type
const initialState: UiState = {
    primaryColor: localStorage.getItem("primaryColor") || "#e91e63",
    secondaryColor: localStorage.getItem("secondaryColor") || "#ffffff",
    themeMode: (localStorage.getItem("themeMode") as "light" | "dark") || "light",
    mobileDrawerOpen: false
};

// Define the slice
export const uiSlice = createSlice({
    name: "ui",
    initialState,
    reducers: {
        setPrimaryColor: (state, action: PayloadAction<string>) => {
            state.primaryColor = action.payload;
            localStorage.setItem("primaryColor", action.payload);
        },
        setSecondaryColor: (state, action: PayloadAction<string>) => {
            state.secondaryColor = action.payload;
            localStorage.setItem("secondaryColor", action.payload);
        },
        setThemeMode: (state, action: PayloadAction<"light" | "dark">) => {
            state.themeMode = action.payload;
            localStorage.setItem("themeMode", action.payload.toString());
        },
        setMobileDrawerOpen: (state, action: PayloadAction<boolean>) => {
            state.mobileDrawerOpen = action.payload;
        }
    }
});

// Define the actions. They are automatically generated by createSlice
export const { setPrimaryColor, setSecondaryColor, setThemeMode, setMobileDrawerOpen } = uiSlice.actions;
export default uiSlice.reducer;
