import axios from "axios";
import { Auth } from "aws-amplify";

const BASE_URL = `${process.env.REACT_APP_API_ENDPOINT}/api/`;

const api = axios.create({
    baseURL: BASE_URL,
    timeout: 30000
});

// Add a request interceptor add a token to the header
api.interceptors.request.use(
    async config => {
        const session = await Auth.currentSession();
        if (!config?.headers) {
            console.log(config);
            throw new Error("config.headers not defined. Unexpected error.");
        }
        if (config.headers.Authorization !== null) {
            config.headers.Authorization = `Bearer ${session.getIdToken().getJwtToken()}`;
        }
        return config;
    },
    error => error
);

export default api;
