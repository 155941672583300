import { Link } from "react-router-dom";
import { Box, Avatar, Button, CardContent, CardHeader, Chip, Stack, Typography, Tooltip, Card, CardActions } from "@mui/material";
import moment from "moment";

import { JobType } from "config/types";
import { LoadingButton } from "@mui/lab";
import api from "config/axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";

import UploadIcon from "@mui/icons-material/Upload";

interface JobCardProps {
    job: JobType;
    getAllJobs: () => void;
}

/**
 * The main job card component. This component is used the important details of a job.
 */
const JobCard = ({ job, getAllJobs }: JobCardProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [jobData, setJobData] = useState<JobType>(job);
    const [uploading, setUploading] = useState(false);

    // Upload File Function
    const uploadTaskFile = async (event) => {
        try {
            setUploading(true);

            // Upload the file to S3
            const formData = new FormData();
            formData.append("file", event.target.files[0]);
            formData.append("jobId", job.id);

            const res = await api.post("job/uploadCaptionFile", formData, {
                timeout: 60 * 60 * 1000,
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });
            jobData.caption_filename = res.data.data.caption_filename;
            jobData.status = res.data.data.satus;
            setJobData(jobData);

            enqueueSnackbar(res.data.message, { variant: "success", autoHideDuration: 1500 });
        }
        catch (error: any) {
            console.log(error);
            if (error.response?.status === 400) {
                enqueueSnackbar(error.response.data.message, { variant: "error", autoHideDuration: 3500 });
            }
            else {
                enqueueSnackbar("Something went wrong", { variant: "error", autoHideDuration: 2000 });
            }
        }
        finally {
            // Reset the file input value
            inputRef.current!.value = "";
            setUploading(false);
        }
    };

    // Get the status color for the job.
    const statusColor = (status: JobType["status"]) => {
        if (status === "completed") {
            return "success.main";
        }

        if (status === "pending") {
            return "warning.main";
        }

        return "info.main";
    };

    return (
        <Card elevation={4} sx={{ padding: 2, height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: "primary.main" }} aria-label="recipe">
                        {jobData.id.slice(0, 2)}
                    </Avatar>
                }
                title={<Tooltip title={jobData.id} placement="top"><Typography variant="body2">{jobData.id.slice(0, 8)}</Typography></Tooltip>}
                subheader={
                    <Stack direction="row">
                        <Typography variant="body2">
                            {`${new Date(jobData.created_at).toLocaleDateString()} ${new Date(jobData.created_at).toLocaleTimeString("en-US")}`}
                        </Typography>
                        <Box sx={{ flexGrow: 1 }}></Box>
                        <Typography variant="body2">
                            {`${moment(jobData.created_at).fromNow()}`}
                        </Typography>
                    </Stack>
                }
                sx={{ padding: 0 }}
            />
            <CardContent sx={{ display: "flex", marginTop: 2, padding: 0, flexGrow: 1 }}>
                <Stack spacing={2} >
                    <Stack sx={{ flexGrow: 1 }} alignContent="center" justifyContent="center">
                        <video style={{ width: "100%" }} controls={true} src={jobData.video_signed_url} />
                    </Stack>
                    {/* Video Data */}
                    <Stack>
                        <Stack direction="row" spacing={1}>
                            <Typography variant="button" sx={{ width: "100px", flexShrink: 0 }} color="primary">
                            Status
                            </Typography>
                            <Typography variant="button" color="primary">
                            :
                            </Typography>
                            <Typography variant="button" sx={{ alignSelf: "center" }} color={statusColor(jobData.status)}>
                                {jobData.status}
                            </Typography>
                        </Stack>

                        <Stack direction="row" spacing={1}>
                            <Typography variant="button" sx={{ width: "100px", flexShrink: 0 }} color="primary">
                            Video File
                            </Typography>
                            <Typography variant="button" color="primary">
                            :
                            </Typography>
                            <Typography variant="body2" sx={{ alignSelf: "center", wordBreak: "break-all" }}>
                                {jobData.video_filename}
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                            <Typography variant="button" sx={{ width: "100px", flexShrink: 0 }} color="primary">
                            Caption File
                            </Typography>
                            <Typography variant="button" color="primary">
                            :
                            </Typography>
                            <Typography variant="body2" sx={{ alignSelf: "center", wordBreak: "break-all" }}>
                                {jobData.caption_filename}
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </CardContent>
            <CardActions sx={{ marginTop: 2, padding: 0 }}>
                <form>
                    <Stack direction="row" alignItems="center">
                        <input type="file" ref={inputRef} style={{ display: "none" }} onChange={uploadTaskFile} />
                        <LoadingButton loading={uploading} loadingPosition="start" variant="contained" color="primary" startIcon={<UploadIcon />}
                            onClick={(e) => inputRef.current?.click()}>
                                Upload
                        </LoadingButton>
                    </Stack>
                </form>
            </CardActions>
        </Card>
    );
};

export default JobCard;
