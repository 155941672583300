import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import AppLayout from "components/appLayout";

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Navigate replace to="/app" />} />
                <Route path="app/*" element={<AppLayout />} />
                <Route path="*" element={<Navigate replace to="/app" />} />
            </Routes>
        </BrowserRouter>
    );
};

export default App;
