import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state
export interface UserState {
    username: string | null;
    email: string | null;
    name: string | null;
    groups: string[];
    isProductManager: boolean;
    isLinguist: boolean;
}

// Define the initial state using that type
const initialState: UserState = {
    username: null,
    email: null,
    name: null,
    groups: [],
    isProductManager: false,
    isLinguist: false
};

// Define the slice
export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUserDetails: (state, action: PayloadAction<UserState>) => {
            state.username = action.payload.username;
            state.email = action.payload.email;
            state.name = action.payload.name;
            state.groups = action.payload.groups;
            state.isProductManager = action.payload.isProductManager;
            state.isLinguist = action.payload.isLinguist;
        }
    }
});

// Define the actions. They are automatically generated by createSlice
export const { setUserDetails } = userSlice.actions;
export default userSlice.reducer;
