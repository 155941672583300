import { Container } from "@mui/material";
import { Navigate, Routes, Route } from "react-router-dom";

import JobsPage from "pages/jobsPage";

import { UserAuth, ProductManagerAuth, LinguistAuth } from "components/auth";
import { useAppDispatch, useAppSelector } from "redux/store";

/**
 * The place where all the content for the application is rendered.
 */
const AppContent = () => {
    const isProductManager = useAppSelector((state) => state.user.isProductManager);
    const isLinguist = useAppSelector((state) => state.user.isLinguist);

    return (
        <Container sx={{ flexGrow: 1, display: "flex", flexDirection: "column", marginTop: 4, paddingBottom: 4 }}>

            {/* Following this convention : https://gist.github.com/mjackson/d54b40a094277b7afdd6b81f51a0393f */}
            <Routes>
                <Route path="jobs/*" element={<JobsPage />} />

                {/* No Route Matched */}
                <Route path="*" element={<Navigate replace to="/app/jobs" />} />

            </Routes>
        </Container>
    );
};

export default AppContent;
