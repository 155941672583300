import { ThemeOptions, createTheme, PaletteMode } from "@mui/material";
import { useState, useMemo } from "react";

export const appThemeConfig = (primaryColor: string, secondaryColor: string, themeMode: PaletteMode): ThemeOptions => {
    return {
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 960,
                lg: 1280,
                xl: 1920
            }
        },
        palette: {
            mode: themeMode,
            primary: {
                main: primaryColor
            },
            secondary: {
                main: secondaryColor
            }
        },
        spacing: 8,
        typography: {
            fontFamily: "\"Poppins\", sans-serif",
            fontSize: 14,
            fontWeightLight: 300,
            fontWeightRegular: 400,
            fontWeightMedium: 500
        }
    };
};
