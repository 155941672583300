import { Box, useTheme } from "@mui/material";
import AppContent from "./appContent";
import AppHeader from "./appHeader";
import AppNavbar from "./appNavbar";
import { Amplify } from "aws-amplify";

import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import amplifyConfig from "config/amplify";
import { useAppDispatch } from "redux/store";
import { setUserDetails, UserState } from "redux/userSlice";

Amplify.configure(amplifyConfig);

/**
 * A layout component for all routes that fall inside the /app path.
 */
const AppLayout = () => {
    const theme = useTheme();
    const dispatch = useAppDispatch();

    const setUserData = (user: any): void => {
        const userState: UserState = {
            username: user.username,
            email: user.attributes.email,
            name: user.attributes.name,
            groups: user.signInUserSession.idToken.payload["cognito:groups"],
            isProductManager: user.signInUserSession.idToken.payload["cognito:groups"]?.includes("atlas-product-managers"),
            isLinguist: user.signInUserSession.idToken.payload["cognito:groups"]?.includes("atlas-linguists")
        };

        dispatch(setUserDetails(userState));
    };

    return (
        <Authenticator loginMechanisms={["email"]} hideSignUp={true}>
            {
                ({ signOut, user }) => {
                    setUserData(user);
                    return (
                        <Box sx={{ display: "flex", flexGrow: 1 }}>
                            <AppNavbar />
                            <Box sx={{ toolbar: theme.mixins.toolbar, display: "flex", flexDirection: "column", flexGrow: 1 }} >
                                <AppHeader signOutFunc={signOut}/>
                                <AppContent />
                            </Box>
                        </Box>
                    );
                }
            }
        </Authenticator>
    );
};

export default AppLayout;
