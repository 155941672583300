import React, { useEffect } from "react";
import { AppBar, Box, Button, IconButton, Switch, Toolbar, Tooltip, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import CircleIcon from "@mui/icons-material/Circle";
import LogoutIcon from "@mui/icons-material/Logout";

import { useAppDispatch, useAppSelector } from "redux/store";
import { setMobileDrawerOpen, setPrimaryColor, setThemeMode } from "redux/uiSlice";
import { ENV_STAGE } from "config/env";

/**
 * The global header for the application used in the AppLayout component.
 */
const AppHeader = ({ signOutFunc }: any) => {
    const dispatch = useAppDispatch();
    const themeMode = useAppSelector(state => state.ui.themeMode);
    const mobileDrawerOpen = useAppSelector(state => state.ui.mobileDrawerOpen);

    const colorList = [
        "#f44336",
        "#ff5722",
        "#ff9800",
        "#ffc107",
        "#ffeb3b",
        "#cddc39",
        "#8bc34a",
        "#4caf50",
        "#009688",
        "#00bcd4",
        "#03a9f4",
        "#2196f3",
        "#3f51b5",
        "#673ab7",
        "#9c27b0",
        "#e91e63"
    ];

    const toggleDrawerOpen = () => {
        dispatch(setMobileDrawerOpen(!mobileDrawerOpen));
    };

    const toggleDarkMode = () => {
        const randomColor = Math.floor(Math.random() * 16777215).toString(16);
        dispatch(setPrimaryColor(`#${randomColor}`));
    };

    return (
        <>
            <AppBar position="sticky" enableColorOnDark>
                <Toolbar>
                    <Box display="flex" alignItems='center' style={{ width: "100%", height: "100%" }}>
                        <IconButton edge="start" color="inherit" onClick={toggleDrawerOpen} aria-label="menu"
                            sx = {{ display: { lg: "none" } }}>
                            <MenuIcon />
                        </IconButton>
                        <Box display="flex" flex={1}>
                            <Typography variant="h6" >
                                LuckyCaps{ENV_STAGE !== "prod" ? ` - ${ENV_STAGE.toUpperCase()}` : ""} - {useAppSelector(state => state.user.name)}
                            </Typography>
                        </Box>
                        <Box sx={{ display: { xs: "none", md: "block" } }}>
                            {
                                colorList.map((color, i) => (
                                    <IconButton key={i} sx={{ color }} onClick={(e) => dispatch(setPrimaryColor(color))}>
                                        <CircleIcon sx={{ filter: "drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.4))" }}/>
                                    </IconButton>
                                ))
                            }

                        </Box>
                        <Switch checked={themeMode === "dark"} color="default" icon={ <DarkModeIcon />} sx={{ marginLeft: 1 }} onChange={(e) => dispatch(setThemeMode(e.target.checked ? "dark" : "light"))}/>
                        <Tooltip title="Logout" aria-label="logout">
                            <IconButton edge="start" color="inherit" size="large" aria-label="logout" onClick={signOutFunc} sx={{ marginLeft: 1 }}>
                                <LogoutIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Toolbar>
            </AppBar>
        </>
    );
};

export default AppHeader;
