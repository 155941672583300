import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { useMemo } from "react";
import { useAppSelector } from "redux/store";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import App from "./app";
import { appThemeConfig } from "./config/theme";

const AppProviders = () => {
    const primaryColor = useAppSelector(state => state.ui.primaryColor);
    const secondaryColor = useAppSelector(state => state.ui.secondaryColor);
    const themeMode = useAppSelector(state => state.ui.themeMode);

    const appTheme = useMemo(() => createTheme(appThemeConfig(primaryColor, secondaryColor, themeMode)), [primaryColor, secondaryColor, themeMode]);

    return (
        <ThemeProvider theme={appTheme}>
            <CssBaseline />
            <SnackbarProvider maxSnack={4}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <App />
                </LocalizationProvider>
            </SnackbarProvider>
        </ThemeProvider>
    );
};

export default AppProviders;
