import {
    Avatar,
    Drawer, Toolbar, Box, List, ListItem,
    ListItemIcon, ListItemText,
    useTheme,
    Divider,
    Stack,
    Typography
} from "@mui/material";
import { NavLink } from "react-router-dom";

import WorkIcon from "@mui/icons-material/Work";

import { useAppDispatch, useAppSelector } from "redux/store";
import { setMobileDrawerOpen } from "redux/uiSlice";
import { CSSProperties } from "react";
import { ENV_STAGE } from "config/env";

/**
 * The content for the nav bar.
 */
const DrawerContent = () => {
    const theme = useTheme();

    const normalLinkStyle: CSSProperties = {
        display: "flex",
        textDecoration: "none",
        color: "inherit"
    };

    const activeLinkStyle: CSSProperties = {
        color: `${theme.palette.primary.main}`,
        backgroundColor: `${theme.palette.primary.main}15`,
        fontWeight: 500,
        borderRight: `4px solid ${theme.palette.primary.main}`
    };

    const navLinkStyle = (obj: { isActive: boolean; isPending: boolean }) => {
        return obj.isActive ? { ...normalLinkStyle, ...activeLinkStyle } : normalLinkStyle;
    };

    return (
        <Box>
            <Toolbar >
                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ flexGrow: 1, transform: `scale(${ENV_STAGE === "prod" ? "1" : "-1"}, 1)` }} >
                    <Avatar src="/images/Lion Complex.png" alt="image" />
                    <Typography variant="h6" sx={{ marginLeft: 1 }}> LuckyCaps </Typography>
                </Stack>
            </Toolbar>
            <Divider />
            <List>
                <NavLink to="/app/jobs" style={navLinkStyle} >
                    <ListItem button>
                        <ListItemIcon sx={{ color: "inherit" }}>
                            <WorkIcon />
                        </ListItemIcon>
                        <ListItemText primary="All Jobs" />
                    </ListItem>
                </NavLink>
            </List>
        </Box>
    );
};

/**
 * The global navigation bar for the application used in the AppLayout component.
 */
const AppNavbar = () => {
    const drawerWidth = 280;

    const dispatch = useAppDispatch();
    const mobileDrawerOpen = useAppSelector(state => state.ui.mobileDrawerOpen);

    const handleDrawerClose = (event: MouseEvent) => {
        dispatch(setMobileDrawerOpen(false));
    };

    return (
        <Box component="nav" sx={{ width: { lg: drawerWidth }, flexShrink: { sm: 0 } }}>
            {/* Mobile Drawer */}
            <Drawer variant="temporary" anchor='left' open={mobileDrawerOpen} onClose={handleDrawerClose} ModalProps={{ keepMounted: true }}
                sx={{
                    display: { xs: "block", lg: "none" },
                    "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth }
                }}>
                <DrawerContent />
            </Drawer>

            {/* Desktop Drawer */}
            <Drawer variant="permanent" open
                sx={{
                    display: { xs: "none", lg: "block" },
                    "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth }
                }}>
                <DrawerContent />
            </Drawer>
        </Box>

    );
};

export default AppNavbar;
