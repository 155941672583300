import React, { useEffect, useState } from "react";
import { Box, LinearProgress, Pagination, Typography, Stack, IconButton, Tooltip } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import api from "config/axios";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import JobCard from "components/jobCard";

/**
 * The main job page for the application.
 */
const JobsPage = () => {
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(0);
    const [totalHits, setTotalHits] = useState(0);
    const [jobsData, setJobsData] = useState<any>();

    const getAllJobs = async () => {
        const response = await api.get(
            "job/getAllJobs",
            {
                params: {
                    page
                }
            }
        );

        setTotalHits(response.data.totalHits);
        setPages(Math.ceil(response.data.totalPages));
        setJobsData(response.data.items);
    };

    const onPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    useEffect(() => {
        getAllJobs();
    }, [page]);

    return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="h4" sx={{ marginBottom: 2 }}>
                All Jobs
                <Tooltip title="All the jobs created are shown here" sx={{ marginLeft: 1 }}>
                    <IconButton>
                        <InfoOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </Typography>
            {
                jobsData === undefined
                    ? (<LinearProgress />)
                    : (
                        <Stack spacing={2}>
                            <Typography variant="h6" sx={{ marginTop: 4, marginBottom: 2 }}>
                                {`Found ${totalHits} jobs`}
                            </Typography>
                            <Grid container spacing={2} sx={{ width: "100%" }}>
                                {
                                    jobsData.map((job, i) => {
                                        return (
                                            <Grid key={i} xs={12} sm={6} lg={4}>
                                                <JobCard job={job} getAllJobs={getAllJobs}/>
                                            </Grid>
                                        );
                                    })
                                }
                            </Grid>
                            <Pagination variant="outlined" color="primary" count={pages} page={page} onChange={onPageChange} sx={{ margin: 4, alignSelf: "center" }} />
                        </Stack>
                    )
            }
        </Box>
    );
};

export default JobsPage;
